<template>
  <b-col
    cols="12"
  >
    <!-- form -->
    <validation-observer ref="creditEvaluationForm">
      <b-form
        @submit.prevent="validationForm"
      >
        <b-card title="Datos de Solicitud">
          <b-row>
            <b-col
              lg="5"
              sm="12"
            >
              <!-- saleFrecuency -->
              <b-form-group
                label-cols-sm="3"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="6"
                label="Frecuencia de venta"
                label-for="saleFrecuency"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Frecuencia de venta"
                  rules="required"
                  vid="saleFrecuency"
                >
                  <b-form-select
                    id="saleFrecuency"
                    v-model="saleFrecuency"
                    :options="optionsSaleFrecuency"
                    :state="errors.length > 0 ? false:null"
                    name="saleFrecuency"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              lg="5"
              sm="12"
            >
              <!-- collectPeriod -->
              <b-form-group
                label-cols-sm="3"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="6"
                label="Plazo de cobro"
                label-for="collectPeriod"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Plazo de cobro"
                  rules="required"
                  vid="collectPeriod"
                >
                  <b-form-select
                    id="collectPeriod"
                    v-model="collectPeriod"
                    :options="optionsCollectPeriod"
                    :state="errors.length > 0 ? false:null"
                    name="collectPeriod"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              lg="5"
              sm="12"
            >
              <!-- currency -->
              <b-form-group
                label-cols-sm="3"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="6"
                label="Moneda"
                label-for="currency"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Moneda"
                  rules="required"
                  vid="currency"
                >
                  <b-form-select
                    id="currency"
                    v-model="currency"
                    :options="optionsCurrency"
                    :state="errors.length > 0 ? false:null"
                    name="currency"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              lg="5"
              sm="12"
            >
              <!-- billingAmount -->
              <b-form-group
                label-cols-sm="3"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="6"
                label="Importe de facturación"
                label-for="billingAmount"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Importe de facturación"
                  rules="required|money"
                  vid="billingAmount"
                >
                  <b-form-input
                    id="billingAmount"
                    v-model="billingAmount"
                    :state="errors.length > 0 ? false:null"
                    name="billingAmount"
                    :formatter="formatterMoney"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Contacto Comercial">
          <b-row>
            <b-col
              lg="5"
              sm="12"
            >
              <!-- comercialContactName -->
              <b-form-group
                label-cols-sm="3"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="6"
                label="Nombres y apellidos"
                label-for="comercialContactName"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nombres y apellidos"
                  rules="required|max:100"
                  vid="comercialContactName"
                >
                  <b-form-input
                    id="comercialContactName"
                    v-model="comercialContactName"
                    :state="errors.length > 0 ? false:null"
                    name="comercialContactName"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              lg="5"
              sm="12"
            >
              <!-- comercialContactPosition -->
              <b-form-group
                label-cols-sm="3"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="6"
                label="Cargo"
                label-for="comercialContactPosition"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Cargo"
                  rules="required"
                  vid="comercialContactPosition"
                >
                  <b-form-input
                    id="comercialContactPosition"
                    v-model="comercialContactPosition"
                    :state="errors.length > 0 ? false:null"
                    name="comercialContactPosition"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              lg="5"
              sm="12"
            >
              <!-- comercialContactCellphone -->
              <b-form-group
                label-cols-sm="3"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="6"
                label="Celular"
                label-for="comercialContactCellphone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Celular"
                  rules="required|phone:0"
                  vid="comercialContactCellphone"
                >
                  <b-form-input
                    id="comercialContactCellphone"
                    v-model="comercialContactCellphone"
                    :state="errors.length > 0 ? false:null"
                    name="comercialContactCellphone"
                    :formatter="formatterTel"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              lg="5"
              sm="12"
            >
              <!-- comercialContactTelephone -->
              <b-form-group
                label-cols-sm="3"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="6"
                label="Teléfono"
                label-for="comercialContactTelephone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Teléfono"
                  rules="required|phone:1"
                  vid="comercialContactTelephone"
                >
                  <b-form-input
                    id="comercialContactTelephone"
                    v-model="comercialContactTelephone"
                    :state="errors.length > 0 ? false:null"
                    name="comercialContactTelephone"
                    :formatter="formatterTel"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              lg="5"
              sm="12"
            >
              <!-- comercialContactAnnex -->
              <b-form-group
                label-cols-sm="3"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="6"
                label="Anexo"
                label-for="comercialContactAnnex"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Anexo"
                  rules="required"
                  vid="comercialContactAnnex"
                >
                  <b-form-input
                    id="comercialContactAnnex"
                    v-model="comercialContactAnnex"
                    :state="errors.length > 0 ? false:null"
                    name="comercialContactAnnex"
                    :formatter="formatterNumber"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Contacto de Tesorería/Finanzas">
          <b-row>
            <b-col
              lg="5"
              sm="12"
            >
              <!-- financeContactName -->
              <b-form-group
                label-cols-sm="3"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="6"
                label="Nombres y apellidos"
                label-for="financeContactName"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nombres y apellidos"
                  rules="required|max:100"
                  vid="financeContactName"
                >
                  <b-form-input
                    id="financeContactName"
                    v-model="financeContactName"
                    :state="errors.length > 0 ? false:null"
                    name="financeContactName"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              lg="5"
              sm="12"
            >
              <!-- financeContactPosition -->
              <b-form-group
                label-cols-sm="3"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="6"
                label="Cargo"
                label-for="financeContactPosition"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Cargo"
                  rules="required"
                  vid="financeContactPosition"
                >
                  <b-form-input
                    id="financeContactPosition"
                    v-model="financeContactPosition"
                    :state="errors.length > 0 ? false:null"
                    name="financeContactPosition"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              lg="5"
              sm="12"
            >
              <!-- financeContactCellphone -->
              <b-form-group
                label-cols-sm="3"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="6"
                label="Celular"
                label-for="financeContactCellphone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Celular"
                  rules="required|phone:0"
                  vid="financeContactCellphone"
                >
                  <b-form-input
                    id="financeContactCellphone"
                    v-model="financeContactCellphone"
                    :state="errors.length > 0 ? false:null"
                    name="financeContactCellphone"
                    :formatter="formatterTel"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              lg="5"
              sm="12"
            >
              <!-- financeContactTelephone -->
              <b-form-group
                label-cols-sm="3"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="6"
                label="Teléfono"
                label-for="financeContactTelephone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Teléfono"
                  rules="required|phone:1"
                  vid="financeContactTelephone"
                >
                  <b-form-input
                    id="financeContactTelephone"
                    v-model="financeContactTelephone"
                    :state="errors.length > 0 ? false:null"
                    name="financeContactTelephone"
                    :formatter="formatterTel"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              lg="5"
              sm="12"
            >
              <!-- financeContactAnnex -->
              <b-form-group
                label-cols-sm="3"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="6"
                label="Anexo"
                label-for="financeContactAnnex"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Anexo"
                  rules="required"
                  vid="financeContactAnnex"
                >
                  <b-form-input
                    id="financeContactAnnex"
                    v-model="financeContactAnnex"
                    :state="errors.length > 0 ? false:null"
                    name="financeContactAnnex"
                    :formatter="formatterNumber"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Otros Datos">
          <b-row>
            <b-col
              lg="10"
              sm="12"
            >
              <!-- productDescription -->
              <b-form-group
                label-cols-sm="12"
                label-cols-lg="12"
                content-cols-sm
                content-cols-lg="12"
                label="Descripción del producto/servicio"
                label-for="productDescription"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Descripción del producto/servicio"
                  rules="required"
                  vid="productDescription"
                >
                  <b-form-textarea
                    id="productDescription"
                    v-model="productDescription"
                    :state="errors.length > 0 ? false:null"
                    name="productDescription"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-row>
          <b-col
            sm="12"
            md="3"
          >
            <!-- submit button -->
            <b-button
              class="my-1"
              variant="primary"
              block
              type="submit"
            >
              Solicitar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-col>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BForm, BFormGroup, BFormInput, BButton, BRow, BCol, BFormSelect, BFormTextarea,
} from 'bootstrap-vue'
import { required } from '@validations'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BButton,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      required,
      userEmail: JSON.parse(localStorage.getItem('userData')).email,
      userRuc: JSON.parse(localStorage.getItem('userData')).ruc,
      saleFrecuency: null,
      collectPeriod: null,
      currency: 'Soles',
      billingAmount: '',
      comercialContactName: '',
      comercialContactPosition: '',
      comercialContactCellphone: '',
      comercialContactTelephone: '',
      comercialContactAnnex: '',
      financeContactName: '',
      financeContactPosition: '',
      financeContactCellphone: '',
      financeContactTelephone: '',
      financeContactAnnex: '',
      productDescription: '',
      // Options
      optionsSaleFrecuency: [
        { value: null, text: 'Por favor seleccionar una frecuencia', disabled: true },
        { value: 'Mensual', text: 'Mensual' },
        { value: 'Semanal', text: 'Semanal' },
        { value: 'Bimestral', text: 'Bimestral' },
        { value: 'Trimestral', text: 'Trimestral' },
        { value: 'Puntual', text: 'Puntual' },
        { value: 'Semestral', text: 'Semestral' },
      ],
      optionsCollectPeriod: [
        { value: null, text: 'Por favor seleccionar un periodo', disabled: true },
        { value: '30', text: '30 días' },
        { value: '60', text: '60 días' },
        { value: '90', text: '90 días' },
        { value: '120', text: '120 días' },
        { value: '150', text: '150 días' },
        { value: '180', text: '180 días' },
      ],
      optionsCurrency: [
        { value: null, text: 'Por favor seleccionar una moneda', disabled: true },
        { value: 'Soles', text: 'Soles (S/)' },
        { value: 'Dolares', text: 'Dólares ($)' },
      ],
    }
  },
  created() {
    const payerId = this.$route.params.id
    if (!payerId) {
      this.$router.replace('/error')
    } else {
      useJwt.getClientPayer({
        payerId,
      }).then(res => {
        this.saleFrecuency = res.data.payer.saleFrecuency
        this.collectPeriod = res.data.payer.collectPeriod
        this.currency = res.data.payer.currency
        this.billingAmount = res.data.payer.billingAmount
        this.comercialContactName = res.data.payer.comercialContact.comercialContactFullName
        this.comercialContactPosition = res.data.payer.comercialContact.comercialContactPosition
        this.comercialContactCellphone = res.data.payer.comercialContact.comercialContactCellphone
        this.comercialContactTelephone = res.data.payer.comercialContact.comercialContactTelephone
        this.comercialContactAnnex = res.data.payer.comercialContact.comercialContactAnnex
        this.financeContactName = res.data.payer.financeContact.financeContactFullName
        this.financeContactPosition = res.data.payer.financeContact.financeContactPosition
        this.financeContactCellphone = res.data.payer.financeContact.financeContactCellphone
        this.financeContactTelephone = res.data.payer.financeContact.financeContactTelephone
        this.financeContactAnnex = res.data.payer.financeContact.financeContactAnnex
        this.productDescription = res.data.payer.productDescription
      })
    }
  },
  methods: {
    formatterNumber(value) {
      return value.replace(/[^0-9]/g, '')
    },
    formatterMoney(value) {
      return value.replace(/[^0-9.]/g, '')
    },
    formatterTel(value) {
      return value.replace(/[^0-9()+]/g, '')
    },
    validationForm() {
      this.$refs.creditEvaluationForm.validate().then(success => {
        if (success) {
          const payerId = this.$route.params.id
          useJwt.createCreditEvaluation({
            saleFrecuency: this.saleFrecuency,
            collectPeriod: this.collectPeriod,
            currency: this.currency,
            billingAmount: this.billingAmount,
            comercialContactName: this.comercialContactName,
            comercialContactPosition: this.comercialContactPosition,
            comercialContactCellphone: this.comercialContactCellphone,
            comercialContactTelephone: this.comercialContactTelephone,
            comercialContactAnnex: this.comercialContactAnnex,
            financeContactName: this.financeContactName,
            financeContactPosition: this.financeContactPosition,
            financeContactCellphone: this.financeContactCellphone,
            financeContactTelephone: this.financeContactTelephone,
            financeContactAnnex: this.financeContactAnnex,
            productDescription: this.productDescription,
            userEmail: this.userEmail,
            userRuc: this.userRuc,
            payerId,
          })
            .then(({ data }) => {
              this.$router.replace('/cliente/panel')
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Éxito',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `Se ha enviado tu solicitud #${data.creditEvaluation} con éxito.`,
                      autoHideDelay: 5000,
                    },
                  })
                })
            })
            .catch(error => {
              console.log('errores', error)
              this.$refs.personalDataForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
}
</script>

<style>

</style>
